<template>
  <div class="user-container">
    <div class="user-card">
      <van-cell>
        <template #icon>
          <img
            src="@/assets/images/avatar.png"
            alt="avatar"
            class="avatar">
        </template>
        <template #title>
          <div class="user-info">
            <span class="username txt-ellipsis" v-if="userInfo.user">{{ userInfo.user.nickName }}</span>
            <span class="username txt-ellipsis" v-if="userInfo.user">{{ userInfo.user.dept.label }}</span>
          </div>
        </template>
        <template #right-icon>
          <img class="logout-img" src="@/assets/images/tuichu.png" alt="" @click="logoutFn">
        </template>
      </van-cell>
    </div>
    <VisitInfo />
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { Dialog } from 'vant'
import { logoutAPI } from '@/api/login'
export default {
  name: 'User',
  components: {
    VisitInfo: () => import('./visitInfo/index.vue')
  },
  data() {
    return {
      // currentDate: ''
    }
  },
  computed: {
    ...mapState({
      userInfo: 'myUserInfo'
    })
  },
  // created() {
  //   this.currentDate = Intl.DateTimeFormat().resolvedOptions().timeZone;
  // },
  methods: {
    ...mapMutations(['setToken', 'setUserInfo']),
    // 登录退出
    logoutFn () {
      Dialog.confirm({
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        message: 'Are you sure to exit？'
      })
        .then(() => {
          logoutAPI().then(res => {
            // 1. 清除token（vuex和本地）
            this.setToken('')
            this.$router.replace('/login')
            this.$store.commit('setToken', '')
            this.$store.commit('setUSERINFO', {})
            localStorage.removeItem('myUserInfo')
            localStorage.removeItem('storeTotal')
            //  2. 跳转到登录页
          })
          
        })
        .catch(() => {

        })
    }
  }
}
</script>

<style scoped lang="less">
.user-container {
  font-size: 12px;
  .user-card {
    background-color: #007bff;
    color: white;
    padding-top: 20px;
    .van-cell {
      display: flex;
      align-items: center;
      background: #007bff;
      color: white;
      &::after {
        display: none;
      }
      .avatar {
        width: 60px;
        height: 60px;
        background-color: #fff;
        border-radius: 50%;
        margin-right: 10px;
      }
      .username {
        font-size: 14px;
        font-weight: bold;
      }
    }
    .user-info {
      display: flex;
      flex-direction: column;
      width: 210px;

    }
    .logout-img {
      align-self: flex-start;
    }
  }
  
  .user-data {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-size: 14px;
    padding: 30px 0;
    .user-data-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 33.33%;
    }
  }
}
</style>
